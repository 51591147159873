<template>
  <div></div>
</template>

<script>
// import { Toast } from "_vant@3.4.5@vant";
export default {
  // 钩子
  mounted: function () {
    window.getDataFromNative = this.getDataFromNative;
    // if (this.userList) {
    //   this.$router.push({
    //     path: "/PerformanceAppraisalList",
    //     query: this.userList,
    //     // path: "/homePage",
    //     // query: this.userList,
    //     // path: "/StoreView",
    //     // query: this.userList,
    //   });
    // }
  },
  data() {
    return {
      // userList: [
      //           7,
      //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI3IiwiZXhwIjoyMDMzNzgxOTQyLCJpYXQiOjE3MTgyNDkxNDJ9.FToT_qrFSoB7EsodMcpFjeVegqYKaJpUEaYuwBpyez8",
      //   // 300,
      //   // "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIzMDAiLCJleHAiOjIwMjgwNzQ2NjMsImlhdCI6MTcxMjU0MTg2M30.2xL-OPQ8LX0SznxlUWEsslJYSY1QDQz_0WIlzMJ5VGI",
      //   // 277,
      //   // "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNzciLCJleHAiOjE5OTczNDM4NjksImlhdCI6MTY4MTcyNDY2OX0.RpLvFYu9Fx6JHduEY-bh_YTpZpy9A5bhw6dHGxZTcGY",
      //   358,
      //   111,
      // ],
      userList: [],
      content: 0,
    };
  },
  methods: {
    getDataFromNative(params) {
      this.userList = params.split(",");
      // var data = {
      //   name: "xiaomili",
      // };
      // return data; //回调给原生，可写可不写
      if (this.userList && this.content == 0) {
        this.content++;
        switch (this.userList[3]) {
          case "110":
            this.$router.push({
              path: "/homePage",
              query: this.userList,
            });
          break;
            case "111":
            this.$router.push({
              path: "/PerformanceAppraisalList",
              query: this.userList,
            });
          break;
            case "999":
              this.$router.push({
                path: "/LocationDistribution",
                query: this.userList,
              });
            break;
        }
      }
    },
  },
};
</script>

<style></style>
