import axios from "axios";

// create an axios instance
const service = axios.create({
  // 本地
  // baseURL: "http://192.168.10.131:8087",
  // 测试
  // baseURL: "http://117.78.17.161:8085",
  // baseURL: "https://api.dbshopplus.com/cg",
  // 正式
  baseURL: "https://api.cap.cdhayaretail.com",
  // withCredentials: true, // send cookies when cross-domain requests 跨域问题
  // baseURL: "http://123.57.204.29:8081", // url = base api url + request url
  // timeout: 15000, // request timeout
  timeout: 25000, // request timeout
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  },
  // headers: {
  //   "Content-Type": "multipart/form-data;charset=UTF-8",
  // },
});
// request拦截器 request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);
// respone拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code && res.code !== 1) {
      return Promise.reject(res || "error");
    } else {
      return Promise.resolve(res);
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
